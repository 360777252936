import React from "react";
import classNames from "classnames";
import { Text, Image, Container, Button } from "@atoms";
import { Socials } from "@molecules";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const StoryOfChange = ({
  label,
  heading,
  subheading,
  subheadingPosition,
  image,
  lead,
  copy,
  bodyImage,
  button,
  socials,
}) => {
  const lang = useLang();
  return (
    <Container className="pb-32 pt-14" frame variant="xl">
      <div className={classNames("px-6 md:px-14")}>
        {label && (
          <Text
            variant="lg"
            className={classNames("mb-4 font-bold uppercase text-white", {})}
          >
            {label}
          </Text>
        )}

        <div className="mb-8">
          {subheading && subheadingPosition === "top" && (
            <Text variant="h3" className="mb-4 text-white">
              {subheading}
            </Text>
          )}
          <Text variant="h1" className="max-w-3xl text-white">
            {heading}
          </Text>
          {subheading && subheadingPosition !== "top" && (
            <Text variant="h6" className="mt-4 text-white">
              {subheading}
            </Text>
          )}
        </div>
        <Image
          aspectRatio={[1.9, 1]}
          ixParams={{ fit: "crop" }}
          image={image}
          caption={`${t("Photo courtesy of", lang)} ${image.attribution}`}
        />
        <div className="my-14">
          <Text className="font-magilio text-4xl text-white md:text-6xl">
            {lead}
          </Text>
        </div>
        <div className="relative z-10 my-12 border-b-3 border-white" />
        <div className="flex flex-wrap flex-gap-10">
          <div className="w-full md:w-1/2">
            <Image
              aspectRatio={[1.56, 1]}
              ixParams={{ fit: "crop" }}
              image={bodyImage}
              caption={`${t("Photo courtesy of", lang)} ${image.attribution}`}
            />
          </div>
          <div className="w-full px-8 md:w-1/2">
            <Text richText className="prose text-white">
              {copy}
            </Text>
            <div className="mt-14 flex flex-wrap items-center gap-8">
              {button?.url && (
                <Button color="black" to={button.url}>
                  {button.text}
                </Button>
              )}
              {!!socials?.length && <Socials socials={socials} />}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StoryOfChange;
